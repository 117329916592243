<template>
  <div class="form-builder-field-editor">
    <el-form ref="fieldForm" :rules="rules" :model="field">
      <custom-el-form-item prop="name" label="Name" label-class="font-bold">
        <el-select
          v-model="field.name"
          filterable
          placeholder="(Choose Field Name)"
          allow-create
          class="w-full"
          @change="onChange"
        >
          <el-option
            v-for="option in FieldNameOptions"
            :key="'field-name-option-' + option.value"
            :value="option.value"
            :label="option.label + ' (' + option.value + ')'"
          />
        </el-select>
      </custom-el-form-item>
      <custom-el-form-item
        prop="label"
        label="Title"
        class="mt-3"
        label-class="font-bold"
      >
        <el-input v-model="field.label" @input="onChange" />
      </custom-el-form-item>
      <custom-el-form-item
        label="Placeholder"
        class="mt-3"
        label-class="font-bold"
        prop="placeholder"
      >
        <el-input v-model="field.placeholder" @input="onChange" />
      </custom-el-form-item>
      <custom-el-form-item
        label="Is Required"
        label-class="font-bold"
        class="mt-3"
        prop="required"
      >
        <custom-el-switch
          v-model="field.required"
          right
          active-text="Yes"
          inactive-text="No"
          green-red
          @input="onChange"
        />
      </custom-el-form-item>
      <custom-el-form-item
        label="Required Group"
        label-class="font-bold"
        class="mt-3"
        prop="required_group"
      >
        <el-select
          v-model="field.required_group"
          placeholder="(Optional Group)"
          class="w-full"
          @change="onChange"
        >
          <el-option
            v-for="option in 5"
            :key="'field-name-option-' + option"
            :value="option"
            :label="option"
          />
        </el-select>
      </custom-el-form-item>
      <custom-el-form-item
        label="Allow Downloads"
        label-class="font-bold"
        class="mt-3"
        prop="allow_downloads"
      >
        <custom-el-switch
          v-model="field.allow_downloads"
          right
          active-text="Yes"
          inactive-text="No"
          green-red
          @input="onChange"
        />
      </custom-el-form-item>
      <custom-el-form-item
        label="Description"
        class="mt-8"
        label-class="font-bold"
        prop="description"
      >
        <text-editor
          v-model="field.description"
          class="w-full"
          @input="onChange"
        />
      </custom-el-form-item>
      <custom-el-form-item
        label="Show Alternate Buyer Description"
        class="mt-8"
        label-class="font-bold"
      >
        <custom-el-switch
          v-model="field.show_buyer_description"
          right
          active-text="Yes"
          inactive-text="No"
          green-red
          @input="onChange"
        />

        <transition name="fadeLeft">
          <text-editor
            v-if="field.show_buyer_description"
            v-model="field.buyer_description"
            class="w-full mt-5"
            @input="onChange"
          />
        </transition>
      </custom-el-form-item>
    </el-form>
  </div>
</template>

<script>
import TextEditor from '@/components/Core/Form/TextEditor';
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import CustomElSwitch from '@/components/Custom/CustomElSwitch';

import { FieldNameOptions } from '@/constants';

export default {
  components: { CustomElSwitch, TextEditor, CustomElFormItem },
  props: {
    value: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      FieldNameOptions,
      rules: {
        name: { validator: this.uniqueName, required: true },
        label: { required: true }
      },
      field: {
        name: '',
        label: '',
        description: '',
        show_buyer_description: false,
        buyer_description: '',
        required: false,
        required_group: null,
        allow_downloads: true,
        props(field) {
          return {
            placeholder: field.placeholder
          };
        },
        ...this.value
      }
    };
  },
  watch: {
    value() {
      this.field = { ...this.field, ...this.value };
    }
  },
  mounted() {
    this.validate();
  },
  methods: {
    validate() {
      this.$refs.fieldForm.validate(valid => {
        this.$emit('valid', valid);
      });
    },
    onChange() {
      this.validate();

      this.$emit('input', this.field);
    },
    uniqueName(rule, value, callback) {
      if (!value) {
        return callback(new Error('Name is required'));
      }

      if (!value.match(/^[a-z][a-z0-9_]+$/)) {
        return callback(
          new Error(
            'Name must start with a letter and use only lower case letters, numbers and the _ character'
          )
        );
      }

      for (let field of this.form.fields) {
        if (field.id !== this.field.id && field.name === this.field.name) {
          return callback(
            new Error(
              `Name must be unique: ${field.label} is already using ${value}`
            )
          );
        }
      }

      callback();
    }
  }
};
</script>
