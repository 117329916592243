var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mediakit-national-tag-paths-query',{staticClass:"page-content py-4 px-16",attrs:{"pager":_vm.pager,"filter":_vm.filter,"sort":_vm.sort},on:{"data":_vm.setNationalTagPaths},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"flex justify-between"},[_c('the-page-header',{attrs:{"is-full-width":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" National Products ")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v(" Add products here to make them available to be booked in national campaigns. ")]},proxy:true},{key:"action",fn:function(){return [_c('div',{staticClass:"justify-end self-center"},[_c('el-button',{staticClass:"button button-green",on:{"click":_vm.onCreateNew}},[_vm._v(" Create New ")])],1)]},proxy:true}],null,true)})],1),_c('div',{staticClass:"flex flex-col"},[_c('mediakit-products-filters',{staticClass:"my-4",model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('mediakit-products-table',{attrs:{"is-loading":isLoading,"national-tag-paths":isLoading ? null : _vm.nationalTagPaths,"sort":_vm.sort},on:{"update:sort":function($event){_vm.sort=$event},"edit":_vm.onEdit,"remove":_vm.onRemove}}),_c('pagination',{staticClass:"mt-6",attrs:{"info":_vm.paginatorInfo,"pager":_vm.pager},on:{"update:pager":function($event){_vm.pager=$event}}})],1),_c('national-tag-path-mutation',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var isSaving = ref.isSaving;
        var createNationalTagPath = ref.createNationalTagPath;
        var updateNationalTagPath = ref.updateNationalTagPath;
        var deleteNationalTagPath = ref.deleteNationalTagPath;
return [(_vm.showNationalProductDialog)?_c('mediakit-product-dialog',{attrs:{"is-saving":isSaving},on:{"save":function($event){return _vm.onSaveNationalTagPath(
              createNationalTagPath,
              updateNationalTagPath
            )},"close":function($event){_vm.showNationalProductDialog = false}},model:{value:(_vm.nationalTagPathInput),callback:function ($$v) {_vm.nationalTagPathInput=$$v},expression:"nationalTagPathInput"}}):_vm._e(),(_vm.showRemoveDialog)?_c('confirm-dialog',{attrs:{"title":"Are you sure?","confirm-text":"Yes","cancel-text":"Never mind","is-saving":isSaving},on:{"confirm":function($event){return _vm.onDeleteNationalTagPath(deleteNationalTagPath)},"close":function($event){_vm.showRemoveDialog = false}}},[_c('h3',{staticClass:"text-center my-12"},[_vm._v(" Are you sure you want to remove \""+_vm._s(_vm.selectedNationalTagPath.name)+"\"? ")])]):_vm._e()]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }