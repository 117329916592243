<template>
  <div
    v-show="!hidden"
    class="form-manager-item p-2 border rounded"
    :class="{
      'border-dark-green border-2 bg-light-green': selected && !editing
    }"
    @click="$emit('select')"
  >
    <div class="item-header p-1 text-lg flex items-center justify-end">
      <template v-if="editing">
        <el-input
          v-model="formInput.name"
          class="mr-3"
          @input="debounceSave({ ...form, name: $event })"
        />
        <a class="p-1" @click.stop="$emit('edit', false)">
          <icon :icon="cancelIcon" />
        </a>
      </template>
      <template v-else>
        <h4 class="flex-grow text-left">{{ form.name || '(No Form Name)' }}</h4>
        <a class="p-1" @click.stop="$emit('edit', true)">
          <icon :icon="editIcon" />
        </a>
        <a
          v-if="form.associationCount === 0"
          class="p-1 text-red"
          @click.stop="showConfirmDelete = true"
        >
          <icon :icon="deleteIcon" />
        </a>
      </template>
    </div>

    <div v-if="editing" class="mt-3">
      <el-form class="p-1">
        <custom-el-form-item
          label="Variations (allows user to fill out the form multiple times)"
          label-class="font-bold"
          class="mt-3"
          prop="variations"
        >
          <el-select
            v-model="formInput.variations"
            placeholder="(Choose Allowed Variations)"
            class="w-full"
            @change="onSave"
          >
            <el-option
              v-for="option in 5"
              :key="'field-variation-option-' + option"
              :value="option"
              :label="option"
            />
          </el-select>
        </custom-el-form-item>
      </el-form>

      <form-builder
        v-if="editing"
        :value="form"
        :default-fields="defaultFields"
        :saving="saving"
        @input="$emit('save', $event)"
        @done="$emit('edit', false)"
      />
    </div>
    <div v-else class="ml-1">
      <div>Associations: {{ form.associationCount }}</div>
      <div>Variations: {{ form.variations }}</div>
      <div>
        <div>Fields</div>
        <div class="ml-3">
          <div
            v-for="field in form.fields"
            :key="'form-field-' + form.id + '-' + field.name"
          >
            <b>{{ field.label }}</b>
            <b v-if="field.required || field.required_group" class="text-red"
              >&nbsp;{{ field.required_group || '*' }}</b
            >
            ({{ field.name }})
          </div>
        </div>
      </div>
    </div>

    <confirm-dialog
      v-if="showConfirmDelete"
      title="Are you sure?"
      content="Are you sure you want to permanently delete this form? This cannot be undone."
      confirm-class="button-red"
      :is-saving="saving"
      @confirm="$emit('delete')"
      @cancel="showConfirmDelete = false"
    />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import FormBuilder from '@/components/Core/Form/FormBuilder/FormBuilder';
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import {
  cancel as cancelIcon,
  pencil as editIcon,
  trashEmpty as deleteIcon
} from '@/vendor/icons';
import { debounce } from 'lodash';

export default {
  components: {
    CustomElFormItem,
    ConfirmDialog,
    FormBuilder
  },
  props: {
    editing: Boolean,
    hidden: Boolean,
    selected: Boolean,
    saving: Boolean,
    form: {
      type: Object,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    defaultFields: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // State
      showConfirmDelete: false,
      formInput: {
        name: this.form.name,
        variations: this.form.variations
      },

      // Icons
      cancelIcon,
      deleteIcon,
      editIcon
    };
  },
  computed: {
    selectedForm() {
      return this.value && this.forms.find(f => f.id === this.value);
    }
  },
  methods: {
    onSave() {
      this.$emit('save', { ...this.form, ...this.formInput });
    },
    debounceSave: debounce(function(input) {
      this.$emit('save', input);
    }, 500)
  }
};
</script>
