<template>
  <section>
    <el-table
      :data="nationalTagPaths"
      border
      class="w-full rounded"
      @sort-change="sortColumns"
      @header-click="headerClick"
    >
      <template v-slot:empty>
        <table-empty-slot
          :is-loading="isLoading"
          :has-data="!!nationalTagPaths"
          empty-message="No inventory found"
          error-message="There was a problem loading the inventory. Please try again later."
        />
      </template>
      <el-table-column sortable="custom" prop="sku" label="SKU">
        <template v-slot="{ row }">
          <div>{{ row.sku }}</div>
        </template>
      </el-table-column>
      <el-table-column sortable="custom" prop="name" label="Name">
        <template v-slot="{ row }">
          <div :ntp-id="row.id">{{ row.name }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="Availability"
        width="60"
        align="center"
        label-class-name="p-2"
      >
        <template v-slot="{ row }">
          <mediakit-products-availability-column :national-tag-path="row" />
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        prop="mediumSuperTag.name"
        label="Medium"
        class-name="align-top"
      >
        <template v-slot="{ row }">
          <super-tag :tag="row.mediumSuperTag" />
        </template>
      </el-table-column>
      <el-table-column label="Forms" class-name="align-top" width="200">
        <template v-slot="{ row }">
          <div>
            <div>
              <div class="font-bold">Creative Form</div>
              <div class="ml-3">
                {{
                  (row.creativeForm && row.creativeForm.name) ||
                    '(Not Selected)'
                }}
              </div>
            </div>
            <div>
              <div class="font-bold">Verification Form</div>
              <div class="ml-3">
                {{
                  (row.verificationForm && row.verificationForm.name) ||
                    '(Not Selected)'
                }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="Verification Notification"
        width="60"
        align="center"
        label-class-name="p-2"
      >
        <template v-slot="{ row }">
          <national-tag-path-mutation>
            <template v-slot="{ loading, updateNationalTagPath }">
              <el-checkbox
                :checked="row.send_verification_notification"
                @change="
                  updateNationalTagPath(row.id, {
                    send_verification_notification: $event
                  })
                "
              />
            </template>
          </national-tag-path-mutation>
        </template>
      </el-table-column>

      <el-table-column
        sortable
        prop="quantity_unit"
        label="Quantity Unit"
        class-name="align-top"
      >
        <template v-slot="{ row }">
          {{ row.quantity_unit || '(N/A)' }}
        </template>
      </el-table-column>

      <el-table-column prop="ad_count" label="Ad Count" class-name="align-top">
        <template v-slot="{ row }">
          <a
            @click="
              $router.push({
                name: 'admin.campaigns-fast',
                query: { filter: { sku: row.sku } }
              })
            "
          >
            {{ row.ad_count | shortNumber }}</a
          >
        </template>
      </el-table-column>

      <el-table-column
        sortable
        prop="priority"
        label="Priority"
        class-name="align-top"
      >
        <template v-slot="{ row }">
          {{ row.priority }}
        </template>
      </el-table-column>

      <el-table-column width="30" class-name="p-0">
        <template v-slot="{ row }">
          <mediakit-products-action-column
            :national-tag-path="row"
            @edit="$emit('edit', $event)"
            @remove="$emit('remove', $event)"
          />
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>
import SuperTag from '@/components/Admin/MediaKit/Products/components/SuperTag';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import NationalTagPathMutation from '@/components/Mutations/Admin/NationalTagPathMutation';
import MediakitProductsActionColumn from './MediakitProductsActionColumn';
import MediakitProductsAvailabilityColumn from './MediakitProductsAvailabilityColumn';

export default {
  components: {
    NationalTagPathMutation,
    SuperTag,
    MediakitProductsActionColumn,
    MediakitProductsAvailabilityColumn,
    TableEmptySlot
  },
  props: {
    value: { type: Object, default: null },
    isLoading: Boolean,
    nationalTagPaths: { type: Array, default: null },
    sort: { type: Object, default: null }
  },
  methods: {
    headerClick(col) {
      if (!col.order) {
        this.$delete(this.sort, col.property);
        this.$emit('update:sort', { updated_at: 'desc' });
      }
    },
    sortColumns(col) {
      if (col.prop && col.order) {
        this.$emit('update:sort', { [col.prop]: col.order });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-table td.align-top {
  vertical-align: top;
}

/deep/ .el-table .cell.p-2 {
  padding: 0.5em;
}

/deep/ .el-table td.p-0 {
  .cell {
    padding: 0;
  }
}
</style>
