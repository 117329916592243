<script>
import {
  createCustomForm,
  deleteCustomForm,
  updateCustomForm
} from '@/graphql/common/forms.mutations.graphql';
import { MutationHelper } from '@/components/Core/Helpers';
import { formatFormInput } from '@/utils/helpers';

export default {
  mixins: [MutationHelper],
  mutation() {
    return {
      mutations: {
        createCustomForm(input) {
          this.refetchCache('getFormsByCategory');
          return this.post(createCustomForm, { input });
        },
        updateCustomForm(form, input) {
          input = formatFormInput(input);
          return this.post(updateCustomForm, { id: form.id, input });
        },
        deleteCustomForm(id) {
          this.refetchCache('getFormsByCategory');
          return this.post(deleteCustomForm, { id });
        }
      }
    };
  }
};
</script>
