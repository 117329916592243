<template>
  <edit-dialog
    width="50%"
    title="Save Product"
    :is-saving="isSaving"
    show-dialog
    @close="$emit('close')"
    @save="onSaveProduct"
  >
    <el-tabs v-model="currentTab">
      <el-tab-pane name="settings" label="Product Settings">
        <mediakit-super-tags-query
          :pager="{ perPage: 0, page: 1 }"
          fetch-policy="network-only"
          @data="superTags = $event.data"
        />
        <el-form
          ref="productForm"
          :disabled="isSaving"
          :rules="rules"
          :model="value"
        >
          <div class="flex flex-row mt-4">
            <custom-el-form-item
              label-class="font-bold"
              class="w-1/2 mr-3"
              label="SKU"
              prop="sku"
            >
              <el-input v-model="value.sku" placeholder="SKU" class="w-full" />
            </custom-el-form-item>
            <custom-el-form-item
              label-class="font-bold"
              class="w-1/2"
              label="Name"
              prop="name"
            >
              <el-input
                v-model="value.name"
                placeholder="Name"
                class="w-full"
              />
            </custom-el-form-item>
          </div>
          <div class="flex flex-row mt-4">
            <custom-el-form-item
              label-class="font-bold"
              class="w-1/2 mr-3"
              label="Medium"
            >
              <custom-el-select
                v-model="value.medium_super_tag_id"
                placeholder="Choose a Medium Super Tag"
                :is-loading="superTags.length === 0"
                select-class="w-full"
                value-by-id
                singular
                hide-icons
                :options="superTags"
              />
            </custom-el-form-item>
            <custom-el-form-item
              label-class="font-bold"
              class="w-1/2"
              label="Quantity Unit"
            >
              <custom-el-select
                v-model="value.quantity_unit"
                placeholder="Choose a Quantity Unit"
                select-class="w-full"
                singular
                hide-icons
                :options="quantityUnits"
              />
            </custom-el-form-item>
          </div>
          <div class="flex flex-row mt-4">
            <custom-el-form-item
              class="flex-grow"
              prop="priority"
              label="Priority"
              label-class="font-bold"
            >
              <el-input
                v-mask.integer=""
                :value="value.priority"
                class="w-full"
                placeholder="0"
                @input="value.priority = parseInt($event)"
              />
            </custom-el-form-item>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane name="creative-form" label="Creative Form">
        <form-manager
          v-model="value.creative_form_id"
          class="mt-5"
          :category="FormCategory.CREATIVE.name"
          :default-fields="DefaultCreativeBuilderFields"
        />
      </el-tab-pane>
      <el-tab-pane name="verification-form" label="Verification Form">
        <form-manager
          v-model="value.verification_form_id"
          class="mt-5"
          :category="FormCategory.VERIFICATION.name"
          :default-fields="DefaultVerificationBuilderFields"
        />
      </el-tab-pane>
    </el-tabs>
  </edit-dialog>
</template>

<script>
import EditDialog from '@/components/Core/EditDialog';
import FormManager from '@/components/Core/Form/FormBuilder/FormManager';
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import CustomElSelect from '@/components/Custom/CustomElSelect';
import { MediakitSuperTagsQuery } from '@/components/Queries';

import {
  DefaultCreativeBuilderFields,
  DefaultVerificationBuilderFields,
  FormCategory
} from '@/constants';

const rule = propName => [
  { required: true, trigger: 'blur', message: `A ${propName} is required` }
];

export default {
  components: {
    FormManager,
    CustomElFormItem,
    EditDialog,
    MediakitSuperTagsQuery,
    CustomElSelect
  },
  props: {
    isSaving: Boolean,
    value: { type: Object, default: null }
  },
  data() {
    return {
      // Consts
      FormCategory,
      DefaultCreativeBuilderFields,
      DefaultVerificationBuilderFields,

      // State
      currentTab: 'settings',
      superTags: [],
      quantityUnits: [
        { label: '(N/A)', value: '' },
        { label: 'Ad Placement', value: 'Ad Placement' },
        { label: 'Distribution', value: 'Distribution' },
        { label: 'Item', value: 'Item' },
        { label: 'Panel', value: 'Panel' },
        { label: 'Poster', value: 'Poster' },
        { label: 'Screen', value: 'Screen' }
      ],
      rules: {
        name: rule('Name')
      }
    };
  },
  methods: {
    onSaveProduct() {
      this.$refs.productForm.validate(async isValid => {
        if (isValid) {
          this.$emit('save');
        }
      });
    },
    reset() {
      this.$refs.productForm.resetFields();
    }
  }
};
</script>
