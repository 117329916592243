<template>
  <mediakit-national-tag-paths-query
    :pager="pager"
    :filter="filter"
    :sort="sort"
    class="page-content py-4 px-16"
    @data="setNationalTagPaths"
  >
    <template v-slot="{ isLoading }">
      <div class="flex justify-between">
        <the-page-header is-full-width>
          <template #title>
            National Products
          </template>
          <template #subtitle>
            Add products here to make them available to be booked in national
            campaigns.
          </template>
          <template #action>
            <div class="justify-end self-center">
              <el-button class="button button-green" @click="onCreateNew">
                Create New
              </el-button>
            </div>
          </template>
        </the-page-header>
      </div>

      <div class="flex flex-col">
        <mediakit-products-filters v-model="filter" class="my-4" />

        <mediakit-products-table
          :is-loading="isLoading"
          :national-tag-paths="isLoading ? null : nationalTagPaths"
          :sort.sync="sort"
          @edit="onEdit"
          @remove="onRemove"
        />

        <pagination class="mt-6" :info="paginatorInfo" :pager.sync="pager" />
      </div>

      <!-- Dialogs -->
      <national-tag-path-mutation>
        <template
          v-slot="{
            isSaving,
            createNationalTagPath,
            updateNationalTagPath,
            deleteNationalTagPath
          }"
        >
          <mediakit-product-dialog
            v-if="showNationalProductDialog"
            v-model="nationalTagPathInput"
            :is-saving="isSaving"
            @save="
              onSaveNationalTagPath(
                createNationalTagPath,
                updateNationalTagPath
              )
            "
            @close="showNationalProductDialog = false"
          />

          <confirm-dialog
            v-if="showRemoveDialog"
            title="Are you sure?"
            confirm-text="Yes"
            cancel-text="Never mind"
            :is-saving="isSaving"
            @confirm="onDeleteNationalTagPath(deleteNationalTagPath)"
            @close="showRemoveDialog = false"
          >
            <h3 class="text-center my-12">
              Are you sure you want to remove "{{
                selectedNationalTagPath.name
              }}"?
            </h3>
          </confirm-dialog>
        </template>
      </national-tag-path-mutation>
    </template>
  </mediakit-national-tag-paths-query>
</template>

<script>
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';
import Pagination from '@/components/Core/Table/Pagination';
import { NationalTagPathMutation } from '@/components/Mutations';
import { MediakitNationalTagPathsQuery } from '@/components/Queries';
import MediakitProductDialog from './components/MediakitProductDialog';
import MediakitProductsFilters from './components/MediakitProductsFilters';
import MediakitProductsTable from './components/MediakitProductsTable';

let NationalTagPathInput = function() {
  return {
    sku: null,
    name: null,
    quantity_unit: null,
    medium_super_tag_id: null,
    priority: 0,
    creative_form_id: null,
    verification_form_id: null
  };
};

export default {
  components: {
    ConfirmDialog,
    MediakitProductDialog,
    MediakitNationalTagPathsQuery,
    MediakitProductsFilters,
    MediakitProductsTable,
    Pagination,
    ThePageHeader,
    NationalTagPathMutation
  },
  data() {
    return {
      filter: {},
      pager: { perPage: 10, page: 1 },
      paginatorInfo: {},
      nationalTagPaths: null,
      nationalTagPathInput: new NationalTagPathInput(),
      selectedNationalTagPath: {},
      showNationalProductDialog: false,
      sort: {
        updated_at: 'desc'
      },
      showRemoveDialog: false
    };
  },
  methods: {
    setNationalTagPaths(data) {
      this.nationalTagPaths = data.data;
      this.paginatorInfo = data.paginatorInfo;
    },
    onEdit(nationalTagPath) {
      this.selectedNationalTagPath = nationalTagPath;
      this.nationalTagPathInput = {
        sku: nationalTagPath ? nationalTagPath.sku : null,
        name: nationalTagPath ? nationalTagPath.name : null,
        priority: nationalTagPath.priority,
        creative_form_id:
          nationalTagPath.creativeForm && nationalTagPath.creativeForm.id,
        verification_form_id:
          nationalTagPath.verificationForm &&
          nationalTagPath.verificationForm.id,
        medium_super_tag_id:
          nationalTagPath && nationalTagPath.mediumSuperTag
            ? nationalTagPath.mediumSuperTag.id
            : null
      };
      this.showNationalProductDialog = true;
    },
    onCreateNew() {
      this.showNationalProductDialog = true;
      this.selectedNationalTagPath = {};
      this.nationalTagPathInput = new NationalTagPathInput();
    },
    async onSaveNationalTagPath(create, update) {
      if (this.selectedNationalTagPath.id) {
        await update(
          this.selectedNationalTagPath.id,
          this.nationalTagPathInput
        );
      } else {
        await create(this.nationalTagPathInput);
      }

      this.showNationalProductDialog = false;
    },
    onRemove(nationalTagPath) {
      this.selectedNationalTagPath = nationalTagPath;
      this.showRemoveDialog = true;
    },
    async onDeleteNationalTagPath(deleteNationalTagPath) {
      await deleteNationalTagPath(this.selectedNationalTagPath.id);
      this.showRemoveDialog = false;
    }
  }
};
</script>
