<template>
  <div class="form-builder">
    <div class="flex">
      <draggable
        class="form-builder-fields w-56 p-3 min-h-10 bg-silver"
        :value="FormBuilderFieldList"
        group="form-builder"
        ghost-class="form-ghost"
        :clone="onClone"
        @start="isDraggingNew = true"
        @end="isDraggingNew = false"
      >
        <div
          v-for="field in FormBuilderFieldList"
          :key="'builder-fields-' + field.id"
          class="form-builder-item flex items-center mb-3"
        >
          <icon :icon="field.icon" />
          <div class="builder-item-title flex-grow pl-3">{{ field.title }}</div>
        </div>
      </draggable>

      <draggable
        v-model="formInput.fields"
        class="form-builder-selected flex-grow px-5 py-3 border-dashed border-2 border-gray ml-5"
        :class="{ 'border-blue': isDraggingNew }"
        group="form-builder"
        ghost-class="form-ghost"
        handle=".field-handle"
        :animation="200"
        @start="isDraggingSelected = true"
        @end="isDraggingSelected = false"
        @change="saveForm"
      >
        <transition-group
          tag="div"
          type="transition"
          :name="isDraggingSelected ? 'flip-list' : null"
          class="w-full h-full"
        >
          <form-builder-field
            v-for="field in formInput.fields"
            :key="'form-item-' + field.id"
            v-model="field.default_value"
            class="form-builder-field"
            :field="field"
            @edit="fieldToEdit = field"
            @remove="onRemoveField(field)"
            @input="saveForm"
          />
        </transition-group>
      </draggable>
    </div>

    <div class="mt-5 flex justify-between">
      <el-button
        class="button-blue flex-grow"
        @click="showFormPreviewDialog = true"
      >
        Preview
      </el-button>
      <el-button
        :disabled="saving"
        class="button-green ml-2 flex-grow"
        @click="$emit('done')"
      >
        {{ saving ? 'Saving...' : 'Done' }}
      </el-button>
    </div>
    <edit-dialog
      v-if="fieldToEdit"
      title="Edit Field"
      width="80%"
      :is-disabled="hasFieldErrors"
      @close="fieldToEdit = false"
      @save="onSaveField"
    >
      <form-builder-field-editor
        v-model="fieldToEdit"
        :form="form"
        @valid="hasFieldErrors = !$event"
      />
    </edit-dialog>

    <form-builder-preview-dialog
      v-if="showFormPreviewDialog"
      :form="form"
      @close="showFormPreviewDialog = false"
    />
  </div>
</template>
<script>
import EditDialog from '@/components/Core/EditDialog';
import FormBuilderField from '@/components/Core/Form/FormBuilder/FormBuilderField';
import FormBuilderFieldEditor from '@/components/Core/Form/FormBuilder/FormBuilderFieldEditor';

import Draggable from 'vuedraggable';
import uuid from 'uuid';
import { cloneDeep } from 'lodash';
import { FormBuilderFieldList } from '@/constants';
import { remove } from '@/utils/helpers';
import FormBuilderPreviewDialog from '@/components/Core/Form/FormBuilder/FormBuilderPreviewDialog';

export default {
  components: {
    FormBuilderPreviewDialog,
    FormBuilderFieldEditor,
    EditDialog,
    FormBuilderField,
    Draggable
  },
  props: {
    saving: Boolean,
    value: {
      type: Object,
      default: null
    },
    defaultFields: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      FormBuilderFieldList,
      isDraggingNew: false,
      isDraggingSelected: false,
      showFormPreviewDialog: false,
      fieldToEdit: null,
      hasFieldErrors: false,
      formInput: {
        fields:
          (this.value && this.value.fields) || cloneDeep(this.defaultFields)
      }
    };
  },
  computed: {
    form() {
      return { name: '', ...this.value, ...this.formInput };
    }
  },
  methods: {
    onClone(field) {
      let name = field.name;
      let postfix = 0;

      // Check the new field name for uniqueness
      checkName: while (true) {
        name = field.name + (postfix ? '_' + postfix : '');

        for (let existingField of this.formInput.fields) {
          if (existingField.name === name) {
            postfix++;
            continue checkName;
          }
        }
        break;
      }

      return {
        required: false,
        ...cloneDeep(field),
        id: uuid(),
        name
      };
    },
    onSaveField() {
      if (this.hasFieldErrors) {
        return false;
      }

      let index = this.formInput.fields.findIndex(
        e => e.id === this.fieldToEdit.id
      );

      if (index >= 0) {
        this.formInput.fields[index] = { ...this.fieldToEdit };
      }

      this.fieldToEdit = false;

      this.saveForm();
    },
    onRemoveField(field) {
      remove(this.form.fields, e => e.id === field.id);

      this.saveForm();
    },
    saveForm() {
      this.$emit('input', { ...this.form });
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';

.form-builder-selected,
.form-builder-fields /deep/ {
  .form-ghost {
    opacity: 0;
  }
}

.form-builder-item {
  cursor: pointer;
  background: $color-blue;
  color: white;
  padding: 0.5em 1em;
  border-radius: 2em;

  &:hover {
    background: $color-dark-blue;
  }
}

.form-builder-field {
  & + .form-builder-field {
    margin-top: 0.5em;
  }
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}
</style>
