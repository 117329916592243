<template>
  <confirm-dialog
    class="form-builder-preview-dialog"
    title="Previewing Custom Form"
    cancel-text="Close"
    width="60em"
    :disabled="true"
    @close="$emit('close')"
  >
    <form-renderer :form="form" />
  </confirm-dialog>
</template>

<script>
import FormRenderer from '@/components/Core/Form/FormBuilder/FormRenderer';
import ConfirmDialog from '@/components/Core/ConfirmDialog';

export default {
  components: { ConfirmDialog, FormRenderer },
  props: {
    form: {
      type: Object,
      required: true
    }
  }
};
</script>
