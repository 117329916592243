<template>
  <div class="form-builder-field flex items-center">
    <div
      class="field-handle w-10 cursor-pointer text-center hover:text-blue flex-shrink-0"
    >
      <icon :icon="handleIcon" />
    </div>

    <div
      class="field-label pr-3 w-32 flex flex-shrink-0 cursor-pointer hover:text-blue"
      @click="$emit('edit')"
    >
      <div
        class="form-label cursor-pointer block"
        :class="{ 'text-dark-silver': !mappedField.label }"
      >
        <div>{{ mappedField.label || '(No Title)' }}</div>
        <div class="text-dark-silver text-sm">({{ mappedField.name }})</div>
      </div>

      <div
        v-if="mappedField.required || mappedField.required_group"
        class="text-red font-bold ml-2"
      >
        {{ mappedField.required_group || '*' }}
      </div>
    </div>
    <div class="flex-grow">
      <div class="form-input">
        <template v-if="mappedField.component">
          <div
            :is="mappedField.component"
            v-mask="mappedField.mask"
            v-bind="fieldProps"
            :value="value"
            @input="$emit('input', $event)"
          />
        </template>
        <template v-else>{{ mappedField.title }}</template>
      </div>
    </div>
    <div class="field-actions flex items-center w-20 flex-shrink-0">
      <a class="ml-3 p-3 text-blue hover:text-dark-blue" @click="$emit('edit')">
        <icon :icon="editIcon" />
      </a>
      <a class="p-3 text-red hover:text-dark-red" @click="$emit('remove')">
        <icon :icon="removeIcon" />
      </a>
    </div>
  </div>
</template>

<script>
import {
  pencil as editIcon,
  sliders as handleIcon,
  trashEmpty as removeIcon
} from '@/vendor/icons';
import { BuilderFields } from '@/constants';

import CustomElSwitch from '@/components/Custom/CustomElSwitch';
import TextEditor from '@/components/Core/Form/TextEditor';
import UploadBox from '@/components/Core/UploadBox';

export default {
  components: {
    CustomElSwitch,
    TextEditor,
    UploadBox
  },
  props: {
    value: {
      type: [Object, Array, String, Number, Boolean],
      default: null
    },
    field: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // Icons
      editIcon,
      handleIcon,
      removeIcon
    };
  },
  computed: {
    mappedField() {
      return { ...BuilderFields[this.field.type], ...this.field };
    },
    fieldProps() {
      let props = {
        ...this.mappedField.props,
        ...(this.mappedField.previewProps || {})
      };

      // Certain components require overriding certain properties (particularly placeholder)
      switch (this.mappedField.component) {
        case 'TextEditor':
          props.config.placeholder = this.mappedField.placeholder;
          break;

        default:
          props.placeholder = this.mappedField.placeholder;
          break;
      }

      return props;
    }
  },
  methods: {}
};
</script>
