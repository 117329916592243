<template>
  <section>
    <el-popover placement="bottom-end" trigger="click" popper-class="p-0 py-3">
      <div
        slot="reference"
        class="text-blue hover:text-dark-blue flex items-center justify-center cursor-pointer px-4"
        @click.stop
      >
        <icon class="icon-lg" :icon="ellipsisIcon" />
      </div>
      <el-button
        class="button-plain hover:text-blue"
        plain
        @click="$emit('edit', nationalTagPath)"
      >
        Edit
      </el-button>
      <el-button
        class="button-plain hover:text-blue"
        plain
        @click="$emit('remove', nationalTagPath)"
      >
        Remove
      </el-button>
    </el-popover>
  </section>
</template>

<script>
import { ellipsisV as ellipsisIcon } from '@/vendor/icons';

export default {
  props: {
    nationalTagPath: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ellipsisIcon
    };
  }
};
</script>

<style></style>
