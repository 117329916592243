<template>
  <mediakit-super-tags-query
    :pager="pager"
    :filter="filter"
    :sort="sort"
    class="page-content py-4 px-16"
    @data="setSuperTags"
  >
    <template v-slot="{ isLoading, query }">
      <div class="flex justify-between">
        <the-page-header is-full-width>
          <template #title>
            Tags
          </template>
          <template #subtitle>
            Choose which publisher tags should feed into national products
          </template>
          <template #action>
            <div class="justify-end self-center">
              <el-button
                class="button-green"
                @click="superTagDialogVisible = true"
              >
                Create New
              </el-button>
            </div>
          </template>
        </the-page-header>
      </div>

      <!-- Dialog -->
      <super-tag-mutation>
        <template v-slot="{ loading, createSuperTag }">
          <mediakit-tags-dialog
            v-if="superTagDialogVisible"
            :is-saving="loading"
            @close="superTagDialogVisible = false"
            @save="onSave(createSuperTag, query, $event)"
          />
        </template>
      </super-tag-mutation>

      <div class="flex flex-col">
        <mediakit-tags-filters v-model="filter" class="my-4" />

        <mediakit-tags-table
          :is-loading="isLoading || isLoadingTypes"
          :super-tags="isLoading ? null : superTags"
          :sort.sync="sort"
          :query="query"
        />

        <pagination class="mt-6" :info="paginatorInfo" :pager.sync="pager" />
      </div>
    </template>
  </mediakit-super-tags-query>
</template>

<script>
import MediakitTagsDialog from './components/MediakitTagsDialog';
import { MediakitSuperTagsQuery } from '@/components/Queries';
import MediakitTagsFilters from './components/MediakitTagsFilters';
import MediakitTagsTable from './components/MediakitTagsTable';
import Pagination from '@/components/Core/Table/Pagination';
import { SuperTagMutation } from '@/components/Mutations';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';

export default {
  components: {
    MediakitTagsDialog,
    MediakitSuperTagsQuery,
    MediakitTagsFilters,
    MediakitTagsTable,
    Pagination,
    SuperTagMutation,
    ThePageHeader
  },
  data() {
    return {
      // State
      isLoadingTypes: false,
      superTagDialogVisible: false,
      filter: {},
      pager: { perPage: 10, page: 1 },
      paginatorInfo: {},
      sort: {
        updated_at: 'desc'
      },

      // Data
      superTags: null
    };
  },
  methods: {
    setSuperTags(data) {
      this.superTags = data.data;
      this.paginatorInfo = data.paginatorInfo;
    },
    async onSave(createSuperTag, query, input) {
      await createSuperTag(input);
      this.superTagDialogVisible = false;
    }
  }
};
</script>
