var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{staticClass:"w-full rounded",attrs:{"data":_vm.editableSuperTags,"border":""},on:{"sort-change":_vm.sortColumns,"header-click":_vm.headerClick},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('table-empty-slot',{attrs:{"is-loading":_vm.isLoading,"has-data":!!_vm.editableSuperTags,"empty-message":"No Super Tags found","error-message":"There was a problem loading the Super Tags. Please try again later."}})]},proxy:true}])},[_c('el-table-column',{attrs:{"sortable":"custom","prop":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('mediakit-tags-name-column',{attrs:{"is-editing":row.isEditing,"is-loading":row.isLoading},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}})]}}])}),_c('el-table-column',{attrs:{"width":"120","label":"Action","label-class-name":"p-2","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('super-tag-mutation',{on:{"done":_vm.onDoneSavingTag,"error":_vm.onError},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var updateSuperTag = ref.updateSuperTag;
var deleteSuperTag = ref.deleteSuperTag;
var loading = ref.loading;
return [_c('mediakit-tags-action-column',{attrs:{"is-loading":loading,"super-tag":row},on:{"cancel":function($event){return _vm.onCancelRow(row)},"edit":function($event){_vm.findTag(row).isEditing = true},"save":function($event){return _vm.onSaveRow(row, updateSuperTag)},"delete":function($event){return _vm.onDeleteRow(row, deleteSuperTag, $event)}}})]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }