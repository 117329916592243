<template>
  <edit-dialog
    width="50%"
    title="Create a new Super Tag"
    :is-saving="isSaving"
    :show-dialog="true"
    @close="onClose"
    @save="onSaveTag"
  >
    <el-form ref="tagForm" :disabled="isSaving" :rules="rules" :model="tagForm">
      <div class="flex flex-row mb-4">
        <custom-el-form-item
          label-class="font-bold"
          class="w-1/2 mr-3"
          label="Name"
          prop="name"
        >
          <el-input v-model="tagForm.name" placeholder="Name" class="w-full" />
        </custom-el-form-item>
      </div>
    </el-form>
  </edit-dialog>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import EditDialog from '@/components/Core/EditDialog';

const rule = propName => [
  { required: true, trigger: 'blur', message: `A ${propName} is required` }
];

export default {
  components: {
    CustomElFormItem,
    EditDialog
  },
  props: {
    isSaving: Boolean
  },
  data() {
    return {
      tagForm: {
        name: ''
      },
      rules: {
        name: rule('Name')
      }
    };
  },
  methods: {
    onSaveTag() {
      this.$refs.tagForm.validate(valid => {
        if (valid) {
          this.$emit('save', this.tagForm);
        }
      });
    },
    onClose() {
      this.$refs.tagForm.resetFields();
      this.$emit('close');
    }
  }
};
</script>
