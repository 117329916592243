<template>
  <national-tag-path-mutation>
    <template v-slot="{ loading, updateNationalTagPath }">
      <el-checkbox
        :checked="nationalTagPath.is_available"
        @change="
          updateNationalTagPath(nationalTagPath.id, { is_available: $event })
        "
      />
    </template>
  </national-tag-path-mutation>
</template>

<script>
import { NationalTagPathMutation } from '@/components/Mutations';

export default {
  components: {
    NationalTagPathMutation
  },
  props: {
    nationalTagPath: {
      type: Object,
      required: true
    }
  }
};
</script>
