<template>
  <section class="flex justify-between">
    <el-input
      v-model="searchText"
      class="w-1/6"
      placeholder="Search"
      prefix-icon="el-icon-search"
      clearable
      :debounce="500"
      @input="onSearchText"
    />
  </section>
</template>

<script>
import { debounce } from 'lodash';

export default {
  props: {
    value: { type: Object, default: null }
  },
  data() {
    return {
      searchText: null
    };
  },
  computed: {
    filter: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', { ...this.value, ...val });
      }
    }
  },
  methods: {
    updateFilter(newFilter) {
      this.filter = newFilter;
    },
    onSearchText: debounce(function() {
      let or = {};
      if (!this.searchText || this.searchText === '') {
        this.$delete(this.filter, 'tagsFilter');
      } else {
        ['id', 'name'].forEach(field => {
          or[field] = {
            like: this.searchText
          };
        });
        this.filter = { tagsFilter: { or } };
      }
    }, 500)
  }
};
</script>
