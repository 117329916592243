<template>
  <section>
    <el-popover placement="bottom-end" trigger="click" popper-class="p-2">
      <div
        slot="reference"
        class="text-blue hover:text-dark-blue flex items-center justify-center cursor-pointer px-4"
        @click.stop
      >
        <icon class="icon-lg" :icon="ellipsisIcon" />
      </div>

      <loading-button v-if="isLoading" />
      <template v-else-if="!isEditing">
        <el-button class="button-plain hover:text-blue" plain @click="onEdit">
          Edit
        </el-button>
        <el-button
          class="button-plain hover:text-red"
          plain
          @click="deleteDialogVisible = true"
        >
          Delete
        </el-button>
      </template>
      <template v-else>
        <el-button class="button-plain hover:text-blue" plain @click="onSave">
          Save
        </el-button>
        <el-button class="button-plain hover:text-blue" plain @click="onCancel">
          Cancel
        </el-button>
      </template>
    </el-popover>
    <confirm-dialog
      v-if="deleteDialogVisible"
      confirm-class="button-red"
      confirm-text="Delete"
      width="40em"
      :is-saving="isLoading"
      @confirm="$emit('delete', superTag.id)"
      @close="deleteDialogVisible = false"
    >
      <div class="text-center text-md">
        <el-alert
          type="error"
          class="flex justify-end w-auto my-3"
          :closable="false"
          title="CAUTION: Are you sure you want to delete this Super Tag?"
          show-icon
        />

        This will remove all associated National Products, possibly rendering
        previously Bookable products non-Bookable.
      </div>
    </confirm-dialog>
  </section>
</template>

<script>
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import LoadingButton from '@/components/Core/Loading/LoadingButton';
import { ellipsisV as ellipsisIcon } from '@/vendor/icons';

export default {
  components: {
    ConfirmDialog,
    LoadingButton
  },
  props: {
    isLoading: Boolean,
    superTag: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ellipsisIcon,
      deleteDialogVisible: false,
      isEditing: false
    };
  },
  methods: {
    onCancel() {
      this.isEditing = false;
      this.$emit('cancel');
    },
    onEdit() {
      this.isEditing = true;
      this.$emit('edit');
    },
    onSave() {
      this.isEditing = false;
      this.$emit('save');
    }
  }
};
</script>
