<template>
  <section class="flex justify-between">
    <div>
      <div>
        <el-input
          v-model="searchText"
          placeholder="Search"
          clearable
          prefix-icon="el-icon-search"
          :debounce="500"
          @input="onSearchText"
        />
      </div>
      <div class="text-gray text-md mt-3">
        Tip: Use comma (,) separated search terms
      </div>
    </div>
    <div>
      <el-select
        v-model="availabilityStatus"
        clearable
        multiple
        placeholder="All Availability Status"
        @change="updateFilter"
      >
        <el-option label="Available" :value="true" />
        <el-option label="Not Available" :value="false" />
      </el-select>
    </div>
  </section>
</template>

<script>
import { debounce } from 'lodash';

export default {
  components: {},
  props: {
    value: { type: Object, default: null }
  },
  data() {
    return {
      availabilityStatus: [],
      searchText: ''
    };
  },
  methods: {
    change(filter) {
      this.$emit('input', { ...this.value, ...filter });
    },
    updateFilter(newFilter) {
      let filter = { is_available: [] };
      newFilter.forEach(item => {
        if (item) filter.is_available.push(1, true);
        else filter.is_available.push(0, false);
      });

      this.change(filter);
    },
    onSearchText: debounce(function() {
      return this.change({ keywords: this.searchText || undefined });
    }, 500)
  }
};
</script>
