<template>
  <el-table
    :data="editableSuperTags"
    border
    class="w-full rounded"
    @sort-change="sortColumns"
    @header-click="headerClick"
  >
    <template v-slot:empty>
      <table-empty-slot
        :is-loading="isLoading"
        :has-data="!!editableSuperTags"
        empty-message="No Super Tags found"
        error-message="There was a problem loading the Super Tags. Please try again later."
      />
    </template>
    <el-table-column sortable="custom" prop="name" label="Name">
      <template v-slot="{ row }">
        <mediakit-tags-name-column
          v-model="row.name"
          :is-editing="row.isEditing"
          :is-loading="row.isLoading"
        />
      </template>
    </el-table-column>
    <el-table-column
      width="120"
      label="Action"
      label-class-name="p-2"
      align="center"
    >
      <template v-slot="{ row }">
        <super-tag-mutation @done="onDoneSavingTag" @error="onError">
          <template v-slot="{ updateSuperTag, deleteSuperTag, loading }">
            <mediakit-tags-action-column
              :is-loading="loading"
              :super-tag="row"
              @cancel="onCancelRow(row)"
              @edit="findTag(row).isEditing = true"
              @save="onSaveRow(row, updateSuperTag)"
              @delete="onDeleteRow(row, deleteSuperTag, $event)"
            />
          </template>
        </super-tag-mutation>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import MediakitTagsActionColumn from './MediakitTagsActionColumn';
import MediakitTagsNameColumn from './MediakitTagsNameColumn';
import { SuperTagMutation } from '@/components/Mutations';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';

export default {
  components: {
    MediakitTagsActionColumn,
    MediakitTagsNameColumn,
    SuperTagMutation,
    TableEmptySlot
  },
  props: {
    superTags: { type: Array, default: null },
    isLoading: Boolean,
    sort: { type: Object, default: null },
    query: { type: Object, default: null }
  },
  data() {
    return {
      editableSuperTags: this.superTags
        ? this.superTags.map(tag => ({
            ...tag,
            isEditing: false,
            isLoading: false
          }))
        : null
    };
  },
  watch: {
    superTags(newValue) {
      this.editableSuperTags = newValue
        ? newValue.map(tag => ({
            ...tag,
            isEditing: false,
            isLoading: false
          }))
        : null;
    }
  },
  methods: {
    findTag(row) {
      if (row && row.id) {
        return this.editableSuperTags.find(tag => tag.id === row.id);
      }
    },
    headerClick(col) {
      if (!col.order) {
        this.$delete(this.sort, col.property);
        this.$emit('update:sort', { updated_at: 'desc' });
      }
    },
    onCancelRow(row) {
      const propTag = this.superTags.find(tag => tag.id === row.id);
      Object.assign(this.findTag(row), { ...propTag, isEditing: false });
    },
    onError() {
      this.editableSuperTags = this.editableSuperTags.map(tag => ({
        ...tag,
        isLoading: false
      }));
    },
    async onSaveRow(row, updateSuperTag) {
      this.findTag(row).isLoading = true;
      await updateSuperTag(row.id, {
        name: row.name
      });
      this.query.refetch();
    },
    async onDeleteRow(row, deleteSuperTag) {
      await deleteSuperTag(row.id);
      this.query.refetch();
    },
    onDoneSavingTag(data) {
      let tag = this.findTag(data.mutateSuperTag.superTag);
      if (tag) {
        tag.isEditing = false;
        tag.isLoading = false;
      }
    },
    sortColumns(col) {
      if (col.prop && col.order) {
        this.$emit('update:sort', { [col.prop]: col.order });
      }
    }
  }
};
</script>
