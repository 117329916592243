<template>
  <div class="super-tag">
    <div class="font-semibold text-blue">{{ tag.name }}</div>
    <div class="ml-3 mediakit-types">
      <div
        v-for="type in tag.types"
        :key="'mediakit-type-' + type.value"
        class="text-dark-silver text-sm leading-tight"
      >
        {{ type.value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tag: {
      type: Object,
      required: true
    }
  }
};
</script>
