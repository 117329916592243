<template>
  <div class="form-manager">
    <forms-query :category="category" @data="forms = $event.formsByCategory" />

    <custom-form-mutation>
      <template
        #default="{createCustomForm, updateCustomForm, deleteCustomForm, isSaving}"
      >
        <div class="selectable-form-list">
          <transition-group
            name="fade"
            mode="out-in"
            class="flex flex-wrap items-stretch animate-position"
          >
            <div
              v-for="form in forms"
              :key="'form-item-' + form.id"
              class="my-2 w-1/3 p-1"
              :class="{
                'w-full': isEditingForm && isEditingForm.id === form.id
              }"
            >
              <form-manager-item
                class="cursor-pointer h-full"
                :form="form"
                :category="category"
                :selected="value === form.id"
                :saving="isSaving"
                :default-fields="defaultFields"
                :editing="isEditingForm && isEditingForm.id === form.id"
                :hidden="isEditingForm && isEditingForm.id !== form.id"
                @edit="isEditingForm = $event ? form : null"
                @save="onUpdateCustomForm(updateCustomForm, form, $event)"
                @delete="deleteCustomForm(form.id)"
                @select="$emit('input', form.id)"
              />
            </div>
          </transition-group>
        </div>

        <div v-if="!isEditingForm" class="mt-5">
          <el-button
            :loading="isSaving"
            class="button-blue w-full"
            @click="onCreateNewForm(createCustomForm)"
          >
            Create New Form
          </el-button>
        </div>
      </template>
    </custom-form-mutation>
  </div>
</template>

<script>
import {
  ok as checkIcon,
  pencil as editIcon,
  trashEmpty as deleteIcon
} from '@/vendor/icons';
import FormsQuery from '@/components/Queries/Common/FormsQuery';
import FormManagerItem from '@/components/Core/Form/FormBuilder/FormManagerItem';
import CustomFormMutation from '@/components/Mutations/Common/CustomFormMutation';

export default {
  components: {
    CustomFormMutation,
    FormManagerItem,
    FormsQuery
  },
  props: {
    value: {
      type: String,
      default: null
    },
    category: {
      type: String,
      required: true
    },
    defaultFields: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isSaving: false,
      isEditingForm: null,
      forms: [],

      // Icons
      checkIcon,
      deleteIcon,
      editIcon
    };
  },
  computed: {
    selectedForm() {
      return this.value && this.forms.find(f => f.id === this.value);
    }
  },
  methods: {
    onCreateNewForm(createCustomForm) {
      createCustomForm({
        name: 'New Custom Form',
        category: this.category,
        fields: []
      });
    },
    onUpdateCustomForm(updateCustomForm, form, input) {
      updateCustomForm(form, input);
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';
</style>
