var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('el-table',{staticClass:"w-full rounded",attrs:{"data":_vm.nationalTagPaths,"border":""},on:{"sort-change":_vm.sortColumns,"header-click":_vm.headerClick},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('table-empty-slot',{attrs:{"is-loading":_vm.isLoading,"has-data":!!_vm.nationalTagPaths,"empty-message":"No inventory found","error-message":"There was a problem loading the inventory. Please try again later."}})]},proxy:true}])},[_c('el-table-column',{attrs:{"sortable":"custom","prop":"sku","label":"SKU"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.sku))])]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{attrs:{"ntp-id":row.id}},[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"label":"Availability","width":"60","align":"center","label-class-name":"p-2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('mediakit-products-availability-column',{attrs:{"national-tag-path":row}})]}}])}),_c('el-table-column',{attrs:{"sortable":"custom","prop":"mediumSuperTag.name","label":"Medium","class-name":"align-top"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('super-tag',{attrs:{"tag":row.mediumSuperTag}})]}}])}),_c('el-table-column',{attrs:{"label":"Forms","class-name":"align-top","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('div',[_c('div',{staticClass:"font-bold"},[_vm._v("Creative Form")]),_c('div',{staticClass:"ml-3"},[_vm._v(" "+_vm._s((row.creativeForm && row.creativeForm.name) || '(Not Selected)')+" ")])]),_c('div',[_c('div',{staticClass:"font-bold"},[_vm._v("Verification Form")]),_c('div',{staticClass:"ml-3"},[_vm._v(" "+_vm._s((row.verificationForm && row.verificationForm.name) || '(Not Selected)')+" ")])])])]}}])}),_c('el-table-column',{attrs:{"label":"Verification Notification","width":"60","align":"center","label-class-name":"p-2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('national-tag-path-mutation',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var updateNationalTagPath = ref.updateNationalTagPath;
return [_c('el-checkbox',{attrs:{"checked":row.send_verification_notification},on:{"change":function($event){return updateNationalTagPath(row.id, {
                  send_verification_notification: $event
                })}}})]}}],null,true)})]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"quantity_unit","label":"Quantity Unit","class-name":"align-top"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_vm._v(" "+_vm._s(row.quantity_unit || '(N/A)')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"ad_count","label":"Ad Count","class-name":"align-top"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('a',{on:{"click":function($event){return _vm.$router.push({
              name: 'admin.campaigns-fast',
              query: { filter: { sku: row.sku } }
            })}}},[_vm._v(" "+_vm._s(_vm._f("shortNumber")(row.ad_count)))])]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"priority","label":"Priority","class-name":"align-top"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.priority)+" ")]}}])}),_c('el-table-column',{attrs:{"width":"30","class-name":"p-0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('mediakit-products-action-column',{attrs:{"national-tag-path":row},on:{"edit":function($event){return _vm.$emit('edit', $event)},"remove":function($event){return _vm.$emit('remove', $event)}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }