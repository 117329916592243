<template>
  <section>
    <el-input
      v-if="isEditing"
      :value="value"
      :disabled="isLoading"
      placeholder="Name"
      class="w-full"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    />
    <div v-else>
      {{ value }}
    </div>
  </section>
</template>

<script>
export default {
  props: {
    isEditing: Boolean,
    isLoading: Boolean,
    value: {
      type: String,
      default: null
    }
  }
};
</script>
