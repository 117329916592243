var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-manager"},[_c('forms-query',{attrs:{"category":_vm.category},on:{"data":function($event){_vm.forms = $event.formsByCategory}}}),_c('custom-form-mutation',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var createCustomForm = ref.createCustomForm;
var updateCustomForm = ref.updateCustomForm;
var deleteCustomForm = ref.deleteCustomForm;
var isSaving = ref.isSaving;
return [_c('div',{staticClass:"selectable-form-list"},[_c('transition-group',{staticClass:"flex flex-wrap items-stretch animate-position",attrs:{"name":"fade","mode":"out-in"}},_vm._l((_vm.forms),function(form){return _c('div',{key:'form-item-' + form.id,staticClass:"my-2 w-1/3 p-1",class:{
              'w-full': _vm.isEditingForm && _vm.isEditingForm.id === form.id
            }},[_c('form-manager-item',{staticClass:"cursor-pointer h-full",attrs:{"form":form,"category":_vm.category,"selected":_vm.value === form.id,"saving":isSaving,"default-fields":_vm.defaultFields,"editing":_vm.isEditingForm && _vm.isEditingForm.id === form.id,"hidden":_vm.isEditingForm && _vm.isEditingForm.id !== form.id},on:{"edit":function($event){_vm.isEditingForm = $event ? form : null},"save":function($event){return _vm.onUpdateCustomForm(updateCustomForm, form, $event)},"delete":function($event){return deleteCustomForm(form.id)},"select":function($event){return _vm.$emit('input', form.id)}}})],1)}),0)],1),(!_vm.isEditingForm)?_c('div',{staticClass:"mt-5"},[_c('el-button',{staticClass:"button-blue w-full",attrs:{"loading":isSaving},on:{"click":function($event){return _vm.onCreateNewForm(createCustomForm)}}},[_vm._v(" Create New Form ")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }