<script>
import { getFormsByCategory } from '@/graphql/common/forms.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    category: {
      type: String,
      required: true
    }
  },
  query() {
    return {
      query: getFormsByCategory,
      variables: {
        category: this.category
      }
    };
  }
};
</script>
